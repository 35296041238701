import App from "next/app";
import Head from "next/head";
import { Normalize } from "styled-normalize";
import SEO from "../config/next-seo.config";
import { DefaultSeo } from "next-seo";
import "../styles/globals.css";

export default class MyApp extends App {
  initGA = () => {
    if (typeof window == "undefined") {
      return;
    }
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }

    gtag("js", new Date());
    gtag("config", process.env.NEXT_PUBLIC_GA);
  };

  render() {
    const { Component, pageProps } = this.props;
    return (
      <>
        <Head>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-SW6FKG3KJ1"
          ></script>
          {this.initGA()}
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, viewport-fit=cover"
          />
        </Head>
        <Normalize />
        <DefaultSeo {...SEO} />
        <Component {...pageProps} />
      </>
    );
  }
}
