import React from "react";
import { NextSeo } from "next-seo";
import { SITE_DESC, SITE_NAME, SITE_URL } from "./config";

const title = SITE_NAME;
const description = SITE_DESC;
const url = SITE_URL;

const SEO = {
  title,
  description,
  canonical: url,
  openGraph: {
    type: "website",
    locale: "en_IE",
    url,
    title,
    description,
    images: [
      {
        url: `${url}/favicon.ico`,
        alt: title,
        width: 1200,
        height: 700,
      },
    ],
  },
};

export const IndexSEO = ({ config }) => {
  return (
    <NextSeo
      title={config.title}
      description={config.description}
      openGraph={{
        url: config.url,
        title: config.title,
        description: config.description,
      }}
      additionalMetaTags={[
        {
          name: "keywords",
          content:
            "jocoos, flipflop, mybeautip, 플리플롭, 마이뷰팁, 라이브커머스, 요쿠스",
        },
      ]}
    />
  );
};

export default SEO;
